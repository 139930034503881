import { useMutation } from '@tanstack/react-query'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { getDatabase, push, update, ref, serverTimestamp } from 'firebase/database'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { ref as storageRef, getStorage, uploadBytes } from 'firebase/storage'
import { useForm, object } from '@kaliber/forms'
import { required, optional } from '@kaliber/forms/validation'
import { routeMap } from '/routeMap'
import { handleResponse } from '/machinery/handleResponse'

import { useClientConfig } from '/machinery/ClientConfig'
import { useTranslate, useLanguage } from '/machinery/I18n'
import { useReportError } from '/machinery/ReportError'
import { useFirebaseApp } from '/machinery/FirebaseAppProvider'
import { requireChecked, requiredTrimmed, emailRequired, phoneRequired, phoneValid, maxFileSize, fileExtension, fileEmpty, requireTermsAndConditions, cvRequired, email } from '/machinery/customFormValidation'
import { getFileExtension } from '/machinery/getFileExtension'
import { trackApplicationStarted, trackApplicationFinished } from '/machinery/tracking/pushToDataLayer'
import { isoCountries } from '/machinery/iso-countries-list'
import { storeHashedEmail } from '/machinery/tracking/storeHashedEmail'
import { useFormFieldRefs } from '/machinery/useFormFieldRefs'
import { moveFocusToError } from '/machinery/a11y/formHelpers'

import { FormFieldCheckbox, FormFieldInput, FormFieldPhoneNumber, FormFieldUpload } from '/features/buildingBlocks/FormField'
import { Questionnaire, PersonalInfoQuestions } from '/features/job/applicationForm/Questionnaire'
import { ButtonPrimary } from '/features/buildingBlocks/Button'
import { Link } from '/features/buildingBlocks/Link'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'

import planeIcon from '/images/plane.raw.svg'

import styles from './JobApplicationForm.css'

const allowedFileExtensions = ['.doc', '.docx', '.pdf']
const allowedMaxFileSize = 20 * 1024 * 1024

export function JobApplicationForm({
  job,
  questionnaireQuestions,
  personalInfoQuestions,
  userOriginData,
}) {
  const { __ } = useTranslate()
  const changeCounterRef = React.useRef(0)
  const trackedBeginCheckoutRef = React.useRef(false)
  const language = useLanguage()
  const firebaseApp = useFirebaseApp()
  const reportError = useReportError()
  const { configEnv } = useClientConfig()

  const { mutate: sendJobApplication, isPending, isError, isSuccess } = useMutation({
    mutationFn: handleSendApplication,
    onError: reportError,
    onSuccess: ({ jobApplicationId, userInfo }) => {
      trackApplicationFinished({ job, jobApplicationId, userInfo })
    }
  })

  const questionnaireFields = useQuestionsAsFields(questionnaireQuestions)
  const personalInfoQuestionsFields = useQuestionsAsFields(personalInfoQuestions)
  const jobCountry = job.country?.[0]?.code || language
  const initialIsoCountryData = isoCountries[jobCountry.toUpperCase()] || isoCountries['NL']

  const { form, submit } = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: { phoneIsoCode: initialIsoCountryData.iso2, phoneDialCode: initialIsoCountryData.dialCode, phoneNumber: '' },
      cvUploadFile: null,
      documentUploadFile: null,
      termsAndConditions: false,
      questionnaireQuestions: React.useMemo(
        () => Object.fromEntries(questionnaireQuestions.map(x => [x.id, x.type === 'checkbox' ? false : ''])),
        [questionnaireQuestions]
      ),
      personalInfoQuestions: React.useMemo(
        () => Object.fromEntries(personalInfoQuestions.map(x => [x.id, x.type === 'checkbox' ? false : ''])),
        [personalInfoQuestions]
      )
    },
    fields: {
      firstName: [requiredTrimmed(__`job-application-form-field-firstName`)],
      lastName: [requiredTrimmed(__`job-application-form-field-lastName`)],
      email: [emailRequired(__`job-application-form-field-email`), email],
      phone: [phoneRequired(__`job-application-form-field-phone`), phoneValid],
      cvUploadFile: [cvRequired, fileExtension(allowedFileExtensions), fileEmpty, maxFileSize(allowedMaxFileSize)],
      documentUploadFile: [optional, fileExtension(allowedFileExtensions), fileEmpty, maxFileSize(allowedMaxFileSize)],
      termsAndConditions: requireTermsAndConditions,
      questionnaireQuestions: object(questionnaireFields),
      personalInfoQuestions: object(personalInfoQuestionsFields)
    },
    onSubmit: handleSubmit
  })

  const { fields: fieldRefs, setRef } = useFormFieldRefs({ formName: form.name })
  const { fields } = form

  return (
    <section data-style-context='light' className={styles.component}>
      {isSuccess ? <ThankYouCard jobTitle={job.job_title} /> : (
        <>
          <h2 className={styles.title}>{job.job_title}</h2>
          <form className={styles.form} noValidate onSubmit={submit} onChange={handleCheckout}>
            <FormFieldInput
              ref={setRef(fields.firstName.name)}
              autoComplete='given-name'
              field={fields.firstName}
              label={__`job-application-form-field-firstName`}
              placeholder={__`job-application-form-field-firstName`}
              required
              inputProps={{ maxLength: 255 }}
              layoutClassName={styles.span1Layout}
            />
            <FormFieldInput
              ref={setRef(fields.lastName.name)}
              autoComplete='family-name'
              field={fields.lastName}
              label={__`job-application-form-field-lastName`}
              placeholder={__`job-application-form-field-lastName`}
              required
              inputProps={{ maxLength: 255 }}
              layoutClassName={styles.span1Layout}
            />
            <FormFieldInput
              ref={setRef(fields.email.name)}
              autoComplete='email'
              field={fields.email}
              label={__`job-application-form-field-email`}
              placeholder={__`job-application-form-field-email`}
              type='email'
              required
              inputProps={{ maxLength: 255 }}
            />
            <FormFieldPhoneNumber
              ref={setRef(fields.phone.name)}
              field={fields.phone}
              label={__`job-application-form-field-phone`}
              required
            />
            <FormFieldUpload
              ref={setRef(fields.cvUploadFile.name)}
              field={fields.cvUploadFile}
              label={__`job-application-form-field-cvUploadFile`}
              ariaLabel={__`job-application-form-field-cvUploadFile-phonetic`}
              required
              inputProps={{ accept: allowedFileExtensions }}
              layoutClassName={styles.span1Layout}
            />
            <FormFieldUpload
              ref={setRef(fields.documentUploadFile.name)}
              field={fields.documentUploadFile}
              label={__`job-application-form-field-documentUploadFile`}
              inputProps={{ accept: allowedFileExtensions }}
              layoutClassName={styles.span1Layout}
            />
            <FormFieldCheckbox
              ref={setRef(fields.termsAndConditions.name)}
              field={fields.termsAndConditions}
              required
              ariaLabel={`${__`job-application-form-field-termsAndConditions`} ${__`privacy-statement`}`}
              label={
                <span>
                  {__`job-application-form-field-termsAndConditions`}
                  &nbsp;
                  <Link href='https://www.rabobank.com/privacy/downloads' dataX='link-to-privacy-statement'>{__`privacy-statement`}</Link>
                </span>
              }
            />
            {Boolean(questionnaireQuestions.length) && (
              <Questionnaire
                fields={fields.questionnaireQuestions.fields}
                {...{ questionnaireQuestions }}
              />
            )}
            {Boolean(personalInfoQuestions.length) && (
              <PersonalInfoQuestions
                fields={fields.personalInfoQuestions.fields}
                {...{ personalInfoQuestions }}
              />
            )}

            <div className={styles.recaptchaNotice}>
              {__`recaptcha-notice-1`}
              <ExternalLink
                href='https://policies.google.com/privacy'
                label={__`privacy-policy`}
                dataX='link-to-privacy-policy'
              />
              {__`recaptcha-notice-2`}
              <ExternalLink
                href='https://policies.google.com/terms'
                label={__`terms-of-service`}
                dataX='link-to-terms-of-service'
              />
              {__`recaptcha-notice-3`}
            </div>

            <div>
              <ButtonPrimary type='submit' dataX='submit-form' disabled={isPending} label={__`job-application-form-button-submit`} />
            </div>

            {isError && <ErrorMessage message={__`job-application-form-general-error`} />}
          </form>
        </>
      )}
    </section>
  )

  function handleCheckout() {
    if (!trackedBeginCheckoutRef.current) {
      changeCounterRef.current++

      if (changeCounterRef.current === 3) {
        trackedBeginCheckoutRef.current = true
        trackApplicationStarted(job)
      }
    }
  }

  function handleSubmit({ invalid, error, value }) {
    if (invalid) {
      moveFocusToError({ refs: fieldRefs, error })
      return
    }

    storeHashedEmail(value.email)

    const {
      cvUploadFile,
      documentUploadFile,
      phone,
      questionnaireQuestions: questionnaireQuestionsRaw,
      personalInfoQuestions: personalInfoQuestionsRaw,
      ...formValues
    } = value

    const { phoneIsoCode, phoneNumber, ...phoneRest } = phone
    const { nationalNumber } = parsePhoneNumberWithError(phoneNumber, phoneIsoCode)

    const questionnaireQuestions = removeEmptyStringEntries(questionnaireQuestionsRaw)
    const personalInfoQuestions = removeEmptyStringEntries(personalInfoQuestionsRaw)

    const { files, storageRefs } = mapFileDataForFiles({ cvUploadFile, documentUploadFile })

    sendJobApplication({
      formValues: {
        ...formValues,
        phone: { ...phoneRest, phoneIsoCode, phoneNumber: nationalNumber, phoneRaw: phoneNumber },
        questionnaireQuestions,
        personalInfoQuestions,
      },
      files,
      storageRefs,
      userOrigin: {
        referer: userOriginData?.referer || null,
        utmMedium: userOriginData?.utmMedium || null,
        utmSource: userOriginData?.utmSource || null,
      }
    })
  }

  async function handleSendApplication({ formValues, files, storageRefs, userOrigin }) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebaseApp))

    const response = await fetch(routeMap.api.v1.getCandidateId(), {
      method: 'POST',
      body: JSON.stringify({ email: formValues.email }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })

    const { candidateId } = await handleResponse(response)

    const jobApplicationQueueRef = ref(getDatabase(firebaseApp), 'services/job-application-processing-service/queue')
    const jobApplicationId = push(jobApplicationQueueRef).key

    if (files) await storeFiles({ uid, jobApplicationId, files, storageRefs })

    await update(jobApplicationQueueRef, {
      [jobApplicationId]: {
        formSubmitDate: serverTimestamp(),
        formValues: {
          ...formValues,
          candidateId
        },
        storageRefs,
        uid,
        language,
        jobId: job.job_id,
        jobCountry,
        userOrigin,
      }
    })

    return {
      jobApplicationId: `KLBR_${configEnv}_${candidateId}_${jobApplicationId.replaceAll(/_|-/g, '')}`,
      userInfo: {
        candidateId: `KLBR_${configEnv}_${candidateId}_${jobApplicationId.replaceAll(/_|-/g, '')}`,
      }
    }
  }

  async function storeFiles({ uid, jobApplicationId, files, storageRefs }) {
    const storage = getStorage(firebaseApp)

    await Promise.all(
      Object.entries(files).map(
        async ([fieldName, file]) => {
          if (!file) return

          const { extension, originalFileName } = storageRefs[fieldName]
          const uploadRef = storageRef(storage, `/uploads/${uid}/${jobApplicationId}/${fieldName}.${extension}`)

          const customMetadata = { originalFileName }

          await uploadBytes(uploadRef, file, { customMetadata })
        }
      )
    )
  }
}

function ExternalLink({ href, label, dataX }) {
  const { __ } = useTranslate()

  return (
    <a
      target='_blank'
      rel='noopener noreferrer'
      data-x={dataX}
      aria-label={__({ x: label })`link-to-x-in-new-window`}
      {...{ href }}
    >
      {label}
    </a>
  )
}

function ThankYouCard({ jobTitle }) {
  const { __ } = useTranslate()
  return (
    <div className={styles.componentThankYouCard} data-style-context='blue' role="region" aria-live="polite">
      <Icon layoutClassName={styles.planeLayout} icon={planeIcon} />
      <HeadingMd h={3} title={__`application-form-thank-you-title`} />
      <p className={styles.thankYouText}>
        {__({ jobTitle })`application-form-thank-you-subtitle`} {__`application-form-what-will-happen-next`}
      </p>
      <Checklist />
    </div>
  )
}

function Checklist() {
  const { __ } = useTranslate()

  return (
    <ul className={styles.componentChecklist}>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-1`}
      </li>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-2`}
      </li>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-3`}
      </li>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-4`}
      </li>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-5`}
      </li>
      <li className={styles.listItem}>
        {__`application-form-what-will-happen-next-step-6`}
      </li>
    </ul>
  )
}

function mapFileDataForFiles(files) {
  return Object.entries(files).reduce(
    (result, [key, file]) => {
      if (!(file instanceof File)) return result

      const { files, storageRefs } = result
      files[key] = file
      storageRefs[key] = {
        extension: getFileExtension(file.name),
        originalFileName: file.name
      }

      return { files, storageRefs }
    },
    { files: {}, storageRefs: {} }
  )
}

function useQuestionsAsFields(questions) {
  return React.useMemo(
    () => Object.fromEntries(questions.map(x => [x.id, x.required ? (x.type === 'checkbox' ? requireChecked : required) : optional])),
    [questions]
  )
}

function ErrorMessage({ message }) {
  return <div className={styles.componentErrorMessage}>{message}</div>
}

function removeEmptyStringEntries(object) {
  return Object.fromEntries(Object.entries(object).filter(([_, value]) => value !== ''))
}
